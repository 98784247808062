import React from "react";
// import { createBrowserHistory } from "history";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Aboutme from "./components/Aboutme";
import ContactInfo from "./components/ContactInfo";
function App() {
  // const hist = createBrowserHistory();
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Aboutme />} />
          <Route path="/contactinfo" element={<ContactInfo />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
