import React from "react";
import "./style.css"
export default function SocialLinked({
  urlGithub,
  urlLinkedIn,
  urlTwitter,
//   urlYoutube,
}) {
  return (
    <>
      <div>
        <ul className="wrapper">
          <a href={urlGithub}>
            <li className="icon github">
              <span className="tooltip">Github</span>
              <span>
                <i className="fa-brands fa-github" />
              </span>
            </li>
          </a>
          <a href={urlLinkedIn}>
            <li className="icon linkedin">
              <span className="tooltip">LinkedIn</span>
              <span>
                <i className="fa-brands fa-linkedin" />
              </span>
            </li>
          </a>
          {/* <li className="icon facebook">
          <span className="tooltip">Facebook</span>
          <span>
            <i className="fa-brands fa-facebook" />
          </span>
        </li> */}
          <a href={urlTwitter}>
            <li className="icon twitter">
              <span className="tooltip">Twitter</span>
              <span>
                <i className="fa-brands fa-twitter" />
              </span>
            </li>
          </a>
          {/* <a href={urlYoutube}>
            <li className="icon youtube">
              <span className="tooltip">Youtube</span>
              <span>
                <i className="fa-brands fa-youtube" />
              </span>
            </li>
          </a> */}
        </ul>
      </div>
    </>
  );
}
