import React from "react";
import "./style.css";
export default function ContactInfo() {
  return (
    <>
      <div className="container">
        <div className="contactUs">
          <div className="title">
            <h2>Get in Touch</h2>
          </div>
          <div className="box">
            <div className="contact form">
              <h3>Send a Message</h3>
              <form>
                <div className="formBox">
                  <div className="row50">
                    <div className="inputBox">
                      <span>First Name</span>
                      <input type="text" placeholder="Channchealy" />
                    </div>
                    <div className="inputBox">
                      <span>Last Name</span>
                      <input type="text" placeholder="Sek" />
                    </div>
                  </div>

                  <div className="row50">
                    <div className="inputBox">
                      <span>E-Mail</span>
                      <input
                        type="text"
                        placeholder="sek.channchealy@yahoo.com"
                      />
                    </div>
                    <div className="inputBox">
                      <span>Phone</span>
                      <input type="text" placeholder="+1 (713) 355 4501" />
                    </div>
                  </div>

                  <div className="row100">
                    <div className="inputBox">
                      <span>Message</span>
                      <textarea placeholder="Write your message here..." />
                    </div>
                  </div>
                  <div className="row100">
                    <div className="inputBox">
                      <input type="submit" value="Send" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="contact info">
              <h3>Contact Info</h3>
              <div className="infoBox">
                <div>
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                  <p>Houston, TX 77064</p>
                </div>
              </div>
              <div className="infoBox">
                <div>
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <a href="mailto:sek.channchealy@yahoo.com">
                    sek.channchealy@yahoo.com
                  </a>
                </div>
              </div>
              <div className="infoBox">
                <div>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  <a href="tel:+17133554501">+1 (713) 355 4501</a>
                </div>
              </div>
            </div>
            <div className="contact map">
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55323.95588756325!2d-95.58403246407376!3d29.929178004481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640ce151d853a6f%3A0xcc1c16b687d2f745!2sHouston%2C%20TX%2077064!5e0!3m2!1sen!2sus!4v1705169178674!5m2!1sen!2sus"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer"
                key={Date.now()}
              ></iframe>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
