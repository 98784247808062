import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import SocialLinked from "../SocialLinked";

// const defaultImageSrc = "/images/image_placeholder.png";

const urlGithub = "https://github.com/";
const urlLinkedIn = "https://linkedin.com/in/";
const urlTwitter = "https://twitter.com/";

// const initialFieldValues = {
//   UserID: 0,
//   Firtname: "",
//   Lastname: "",
//   Username: "",
//   Email: "",
//   Phone: "",
//   Skill: "",
//   Decription: "",
//   Github: "",
//   LinkedIn: "",
//   Twitter: "",
//   Youtube: "",
//   DateOfJoining: "",
//   ImageSrc: defaultImageSrc,
//   ImageFileName: null,
// };

export default function Aboutme() {
  const [aboutme, setAboutme] = useState([]);

  useEffect(() => {
    refreshAboutme();
  }, []);

  const aboutmeAPI = (url = "http://208.167.242.69:5024/api/aboutme/") => {
    // const aboutmeAPI = (url = "http://localhost:5159/api/aboutme/") => {
    return {
      fetchAll: () => axios.get(url),
      // create: (newRecord) => axios.post(url, newRecord),
      // update: (id, updatedRecord) => axios.put(url + id, updatedRecord),
      // delete: (id) => axios.delete(url + id),
    };
  };

  function formatPhoneNumber(phoneNumber) {
    // convert the raw number to (xxx) xxx-xxx format
    const x = phoneNumber
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ""}`;
  }

  const aboutCard = (data) => (
    <div className="aboutme-container">
      <div className="img-container">
        <img src={data.imageSrc} alt="" />
      </div>
      <div className="body-text">
        <div>Hello, I'm</div>
        <div className="fullname">
          {data.Firtname} {data.Lastname}
        </div>
        <div className="skill">{data.Skill}</div>
        <div className="description">{data.Decription}</div>
        <div className="button-download">
          <a
            style={{ textDecoration: "none", color: "#000" }}
            href="/docs/resume/ChannchealySek_Resume.pdf"
            target="_blank"
          >
            <span className="button-text"> Dowload Resume</span>
            <span className="button-icon">
              <i className="fa-solid fa-download" />
            </span>
          </a>
        </div>
        {/* <div className="email">{data.Email}</div> */}
        {/* <div className="phone">{data.Phone.replace(/(\d{3})(\d{3})(\d{4})/,"($1)$2-$3")}</div> */}
        {/* <div className="phone">{formatPhoneNumber(data.Phone)}</div> */}
        <div className="social-container">
          <SocialLinked
            urlGithub={urlGithub + data.Github}
            urlLinkedIn={urlLinkedIn + data.LinkedIn}
            urlTwitter={urlTwitter + data.Twitter}
          />
        </div>
      </div>
    </div>
  );

  function refreshAboutme() {
    aboutmeAPI()
      .fetchAll()
      .then((res) => setAboutme(res.data))
      .catch((err) => console.log(err));
  }

  return (
    <div className="aboutme-container">
      <div className="col-md-12">
        {
          //tr > 3 td
          [...Array(Math.ceil(aboutme.length))].map((e, i) => (
            <div key={i}>
              <div>{aboutCard(aboutme[i])}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
