import React, { useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import Aboutme from "../Aboutme";
const logoImageSrc = "/images/sc-logo.png";

export default function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <nav className="navbar">
        <NavLink to="/" className="logo">
        <img src={logoImageSrc} width="45" height="45" alt="logo sc"></img>
        </NavLink>
        <div
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setIsMobile(false)}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "aboutme"
                : "aboutme-notactive"
            }
          >
            <div>Aboutme</div>
          </NavLink>
          <NavLink
            to="/ContactInfo"
            className={({ isActive }) =>
              isActive
                ? "contactinfo"
                : "contactinfo-notactive"
            }
          >
            <div>Contactme</div>
          </NavLink>
        </div>
        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <i className="fa fa-times"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </button>
      </nav>
    </>
  );
}
